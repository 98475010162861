import "./style.css";
import { ModalLink } from "react-router-modal-gallery";

function Home() {
  return (
    <div className="App">
      <header className="App-header container-fluid">
        <h1 className="display-4">Welcome to</h1>
        <br></br>
        <h1
          style={{ backgroundColor: "black", color: "white" }}
          className="font-weight-bold p-4"
        >
          Fanatic4Tech LLC
        </h1>
        <br></br>
        <h1 className="display-4">You deserve more than just a Website</h1>
        <br></br>
        <p className="lead mx-lg-5 font-weight-bold p-4">
          We design, build, deploy, and maintain websites. Our ultimate goal is
          to give our end-users better experience and complete satisfaction. Our
          prices are affordable while our work is top notch! Contact us today
          for a free price quote. ---After changes!! just testing...again
        </p>
        <br></br>
        <ModalLink
          className="bg-primary text-light px-5 py-3"
          style={{
            textDecoration: "none",
          }}
          to="/contact"
        >
          Get In Touch
        </ModalLink>
      </header>
    </div>
  );
}

export default Home;
