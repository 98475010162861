import "./style.css";
import syl from "./syl.jpg";
import ivan from "./ivan.jpg";
import janvier from "./janvier.jpg";

function Bio() {
  return (
    <div className="App">
      <section className="App-header container-fluid">
        <h1 className="display-4">Welcome to</h1>
        <br></br>
        <h1
          style={{ backgroundColor: "black", color: "white" }}
          className="font-weight-bold p-4"
        >
          Team's Bio Page
        </h1>
        <br></br>
        <h1 className="display-4">Janvier Mbilizi</h1>
        <br></br>
        <img src={janvier} alt="Janvier Bio" />
        <br></br>
        <p style={{ backgroundColor: "black", color: "white" }} className="lead mx-lg-5">
          Senior Full Stack Web developer from Washington, DC. A part-time web
          development instructor at 2U. His greatest strengths are "can do"
          mentality and cordiality.
        </p>
      </section>
      <br></br>
      <section className="App-header container-fluid">
        <br></br>
        <h1 className="display-4">Sylvester Nwizu</h1>
        <br></br>
        <img src={syl} alt="Sylvester Bio" />
        <br></br>
        <p style={{ backgroundColor: "black", color: "white" }} className="lead mx-lg-5">
          Logic minded Full Stack Web developer from Nashville, TN, with a
          background in healthcare. An individual with an insatiable appetite
          for learning.
        </p>
      </section>
      <br></br>
      <section className="App-header container-fluid">
        <br></br>
        <h1 className="display-4">Ivan Duranic</h1>
        <br></br>
        <img src={ivan} alt="Ivan Bio" />
        <br></br>
        <p style={{ backgroundColor: "black", color: "white" }} className="lead mx-lg-5">
          Decorated former Army Officer, earned his certificate in Full Stack
          Web Development at the University of Toronto. Writer. Sportsman.
        </p>
      </section>
    </div>
  );
}

export default Bio;
